export const API_AUTH_LOGIN = "API_AUTH_LOGIN";
export const API_AUTH_LOGOUT = "API_AUTH_LOGOUT";
export const API_USER_IDENTITY = "API_USER_IDENTITY";
//Manage source
export const API_FETCH_SOURCES = "API_FETCH_SOURCES";
export const API_FETCH_SOURCES_FILE = "API_FETCH_SOURCES_FILE";
export const API_FETCH_SOURCE = "API_FETCH_SOURCE";
export const API_CREATE_SOURCE = "API_CREATE_SOURCE";
export const API_DELETE_SOURCE = "API_DELETE_SOURCE";
export const API_UPDATE_SOURCE = "API_UPDATE_SOURCE";
//Source log
export const API_FETCH_SOURCE_LOG = "API_FETCH_SOURCE_LOG";
export const API_FETCH_SOURCE_LOG_FILE = "API_FETCH_SOURCE_LOG_FILE";
//Manage channel
export const API_FETCH_CHANNELS = "API_FETCH_CHANNELS";
export const API_FETCH_CHANNELS_FILE = "API_FETCH_CHANNELS_FILE";
export const API_FETCH_CHANNEL = "API_FETCH_CHANNEL";
export const API_CREATE_CHANNEL = "API_CREATE_CHANNEL";
export const API_DELETE_CHANNEL = "API_DELETE_CHANNEL";
export const API_UPDATE_CHANNEL = "API_UPDATE_CHANNEL";
export const API_FETCH_CHANNEL_PARSING_HISTORY =
  "API_FETCH_CHANNEL_PARSING_HISTORY";
//Manage custom source
export const API_FETCH_CUSTOM_SOURCES = "API_FETCH_CUSTOM_SOURCES";
export const API_FETCH_CUSTOM_SOURCES_FILE = "API_FETCH_CUSTOM_SOURCES_FILE";
export const API_FETCH_CUSTOM_SOURCE = "API_FETCH_CUSTOM_SOURCE";
export const API_DELETE_CUSTOM_SOURCE = "API_DELETE_CUSTOM_SOURCE";
export const API_UPDATE_CUSTOM_SOURCE = "API_UPDATE_CUSTOM_SOURCE";
//Manage tags
export const API_FETCH_TAGS = "API_FETCH_TAGS";
export const API_FETCH_TAG_NAMES = "API_FETCH_TAG_NAMES";
export const API_FETCH_CUSTOM_TAG_NAMES = "API_FETCH_CUSTOM_TAG_NAMES";
export const API_FETCH_TAGS_FILE = "API_FETCH_TAGS_FILE";
export const API_FETCH_TAG = "API_FETCH_TAG";
export const API_CREATE_TAG = "API_CREATE_TAG";
export const API_DELETE_TAG = "API_DELETE_TAG";
export const API_UPDATE_TAG = "API_UPDATE_TAG";
//Accounts
export const API_CREATE_ACCOUNT = "API_CREATE_ACCOUNT";
export const API_FETCH_ACCOUNTS = "API_FETCH_ACCOUNTS";
export const API_FETCH_ACCOUNT = "API_FETCH_ACCOUNT";
export const API_UPDATE_ACCOUNT_SUBSCRIPTION = "API_UPDATE_ACCOUNT_SUBSCRIPTION";
export const API_FETCH_ACCOUNT_TRANSACTIONS = "API_FETCH_ACCOUNT_TRANSACTIONS";
export const API_CREATE_ACCOUNT_TRANSACTION = "API_CREATE_ACCOUNT_TRANSACTION";
export const API_FETCH_ACCOUNT_PACKAGE = "API_FETCH_ACCOUNT_PACKAGE";
export const API_FETCH_ACCOUNT_PACKAGES = "API_FETCH_ACCOUNT_PACKAGES";
export const API_UPDATE_ACCOUNT_PACKAGE = "API_UPDATE_ACCOUNT_PACKAGE";
export const API_FETCH_ACCOUNT_USERS = "API_FETCH_ACCOUNT_USERS";
export const API_DELETE_USER = "API_DELETE_USER";
export const API_RESET_USER_PASSWORD = "API_RESET_USER_PASSWORD";
export const API_CREATE_USER = "API_CREATE_USER";
export const API_PATCH_USER = "API_PATCH_USER";
export const API_PATCH_ACCOUNT = "API_PATCH_ACCOUNT";
export const API_FETCH_USER_LOGS = "API_FETCH_USER_LOGS";
// Manage proxy packages
export const API_FETCH_PROXY_PACKAGES = "API_FETCH_PROXY_PACKAGES";
export const API_FETCH_PROXY_PACKAGES_FILE = "API_FETCH_PROXY_PACKAGES_FILE";
export const API_FETCH_PROXY_PACKAGE = "API_FETCH_PROXY_PACKAGE";
export const API_CREATE_PROXY_PACKAGE = "API_CREATE_PROXY_PACKAGE";
export const API_DELETE_PROXY_PACKAGE = "API_DELETE_PROXY_PACKAGES";
export const API_UPDATE_PROXY_PACKAGE = "API_UPDATE_PROXY_PACKAGE";
// Manage proxies
export const API_FETCH_PROXIES = "API_FETCH_PROXIES";
export const API_FETCH_PROXIES_FILE = "API_FETCH_PROXIES_FILE";
export const API_FETCH_PROXY = "API_FETCH_PROXY";
export const API_CREATE_PROXY = "API_CREATE_PROXY";
export const API_UPDATE_PROXY = "API_UPDATE_PROXY";
export const API_DELETE_PROXY = "API_DELETE_PROXY";
export const API_IMPORT_PROXY = "API_IMPORT_PROXY";
export const API_GET_PROXY_TEMPLATE = "API_GET_PROXY_TEMPLATE";
//Dictionaries
export const API_FETCH_ACCOUNT_DICTIONARY = "API_FETCH_ACCOUNT_DICTIONARY";
export const API_FETCH_PACKAGE_DICTIONARY = "API_FETCH_PACKAGE_DICTIONARY";
export const API_FETCH_ACTION_GROUP_DICTIONARY =
  "API_FETCH_ACTION_GROUP_DICTIONARY";
export const API_FETCH_PARSING_GROUP_DICTIONARY =
  "API_FETCH_PARSING_GROUP_DICTIONARY";

// Subscription packages
export const API_FETCH_SUBSCRIPTION_PACKAGES =
  "API_FETCH_SUBSCRIPTION_PACKAGES";
export const API_DELETE_SUBSCRIPTION_PACKAGE =
  "API_DELETE_SUBSCRIPTION_PACKAGE";
export const API_CREATE_SUBSCRIPTION_PACKAGE =
  "API_CREATE_SUBSCRIPTION_PACKAGE";
export const API_UPDATE_SUBSCRIPTION_PACKAGE =
  "API_UPDATE_SUBSCRIPTION_PACKAGE";

//Manage Admins
export const API_FETCH_ADMINS = "API_FETCH_ADMINS";
export const API_CREATE_ADMIN = "API_CREATE_ADMIN";
export const API_DELETE_ADMIN = "API_DELETE_ADMIN";
export const API_UPDATE_ADMIN = "API_UPDATE_ADMIN";
export const API_SET_ADMIN_PERMISSIONS = "API_SET_ADMIN_PERMISSIONS";
export const API_FETCH_ACL_RESOURCES = "API_FETCH_ACL_RESOURCES";

//Parsing group
export const API_FETCH_PARSING_GROUPS = "API_FETCH_PARSING_GROUPS";
export const API_FETCH_PARSING_GROUP = "API_FETCH_PARSING_GROUP";
export const API_CREATE_PARSING_GROUP = "API_CREATE_PARSING_GROUP";
export const API_DELETE_PARSING_GROUP = "API_DELETE_PARSING_GROUP";
export const API_UPDATE_PARSING_GROUP = "API_UPDATE_PARSING_GROUP";

// Dashboard
export const API_GET_DASHBOARD = "API_GET_DASHBOARD";

//Manage project
export const API_FETCH_PROJECTS = "API_FETCH_PROJECTS";
export const API_FETCH_PROJECT = "API_FETCH_PROJECT";
export const API_UPDATE_PROJECT = "API_UPDATE_PROJECT";
export const API_FETCH_PROJECT_LOGS = "API_FETCH_PROJECT_LOGS";
export const API_FETCH_PROJECT_MANUAL_ARTICLES = "API_FETCH_PROJECT_MANUAL_ARTICLES";
export const API_CREATE_ARTICLE = "API_CREATE_ARTICLE";
export const API_FETCH_PROJECT_TASKS = "API_FETCH_PROJECT_TASKS";
export const API_CREATE_PROJECT_TASK = "API_CREATE_PROJECT_TASK";

export const apiRoutes = {
  host: process.env.VUE_APP_API_HOST,
  methods: {
    // AUTH
    [API_AUTH_LOGIN]: {
      url: "/auth/login",
      httpMethod: "POST",
    },
    [API_AUTH_LOGOUT]: {
      url: "/auth/logout",
      httpMethod: "POST",
    },
    [API_USER_IDENTITY]: {
      url: "/profile",
      httpMethod: "GET",
    },
    // SOURCES
    [API_FETCH_SOURCES]: {
      url: "/sources",
      httpMethod: "GET",
    },
    [API_FETCH_SOURCES_FILE]: {
      url: "/sources/export",
      httpMethod: "GET",
    },
    [API_FETCH_SOURCE]: {
      url: "/source/${id}",
      httpMethod: "GET",
    },
    [API_CREATE_SOURCE]: {
      url: "/source",
      httpMethod: "POST",
    },
    [API_DELETE_SOURCE]: {
      url: "/source/${id}",
      httpMethod: "DELETE",
    },
    [API_UPDATE_SOURCE]: {
      url: "/source/${id}",
      httpMethod: "PUT",
    },
    // CUSTOM SOURCES
    [API_FETCH_CUSTOM_SOURCES]: {
      url: "/sources/custom",
      httpMethod: "GET",
    },
    [API_FETCH_CUSTOM_SOURCES_FILE]: {
      url: "/sources/custom/export",
      httpMethod: "GET",
    },
    [API_FETCH_CUSTOM_SOURCE]: {
      url: "/source/custom/${id}",
      httpMethod: "GET",
    },
    [API_DELETE_CUSTOM_SOURCE]: {
      url: "/source/custom/${id}",
      httpMethod: "DELETE",
    },
    [API_UPDATE_CUSTOM_SOURCE]: {
      url: "/source/custom/${id}",
      httpMethod: "PUT",
    },
    // SOURCE LOG
    [API_FETCH_SOURCE_LOG]: {
      url: "/source/${id}/log",
      httpMethod: "GET",
    },
    [API_FETCH_SOURCE_LOG_FILE]: {
      url: "/source/${id}/log/export",
      httpMethod: "GET",
    },
    // CHANNELS
    [API_FETCH_CHANNELS]: {
      url: "/channels",
      httpMethod: "GET",
    },
    [API_FETCH_CHANNELS_FILE]: {
      url: "/channels/export",
      httpMethod: "GET",
    },
    [API_FETCH_CHANNEL]: {
      url: "/channel/${id}",
      httpMethod: "GET",
    },
    [API_CREATE_CHANNEL]: {
      url: "/channel",
      httpMethod: "POST",
    },
    [API_UPDATE_CHANNEL]: {
      url: "/channel/${id}",
      httpMethod: "PUT",
    },
    [API_FETCH_CHANNEL_PARSING_HISTORY]: {
      url: "/channel/${id}/parsing_history",
      httpMethod: "GET",
    },
    [API_DELETE_CHANNEL]: {
      url: "/channel/${id}",
      httpMethod: "DELETE",
    },
    // TAGS
    [API_FETCH_TAG_NAMES]: {
      url: "/tags/names",
      httpMethod: "GET",
    },
    [API_FETCH_CUSTOM_TAG_NAMES]: {
      url: "/tags/names/custom",
      httpMethod: "GET",
    },
    [API_FETCH_TAGS]: {
      url: "/tags",
      httpMethod: "GET",
    },
    [API_FETCH_TAGS_FILE]: {
      url: "/tags/export",
      httpMethod: "GET",
    },
    [API_FETCH_TAG]: {
      url: "/tag/${id}",
      httpMethod: "GET",
    },
    [API_CREATE_TAG]: {
      url: "/tag",
      httpMethod: "POST",
    },
    [API_UPDATE_TAG]: {
      url: "/tag/${id}",
      httpMethod: "PUT",
    },
    [API_DELETE_TAG]: {
      url: "/tag/${id}",
      httpMethod: "DELETE",
    },
    // Accounts
    [API_FETCH_ACCOUNTS]: {
      url: "/accounts",
      httpMethod: "GET",
    },
    [API_CREATE_ACCOUNT]: {
      url: "/account",
      httpMethod: "POST",
    },
    [API_FETCH_ACCOUNT]: {
      url: "/account/${id}",
      httpMethod: "GET",
    },
    [API_UPDATE_ACCOUNT_SUBSCRIPTION]: {
      url: "/account/${id}/subscription",
      httpMethod: "PUT",
    },
    [API_FETCH_ACCOUNT_TRANSACTIONS]: {
      url: "/account/${id}/transactions",
      httpMethod: "GET",
    },
    [API_CREATE_ACCOUNT_TRANSACTION]: {
      url: "/account/${id}/transaction",
      httpMethod: "POST",
    },
    [API_FETCH_ACCOUNT_PACKAGES]: {
      url: "/account/${id}/packages",
      httpMethod: "GET",
    },
    [API_FETCH_ACCOUNT_PACKAGE]: {
      url: "/account/${id}/package",
      httpMethod: "GET",
    },
    [API_UPDATE_ACCOUNT_PACKAGE]: {
      url: "/account/${id}/package",
      httpMethod: "PUT",
    },
    [API_FETCH_ACCOUNT_USERS]: {
      url: "/users",
      httpMethod: "GET",
    },
    [API_FETCH_USER_LOGS]: {
      url: "/users/log",
      httpMethod: "GET",
    },
    [API_DELETE_USER]: {
      url: "/user/${id}",
      httpMethod: "DELETE",
    },
    [API_CREATE_USER]: {
      url: "/user",
      httpMethod: "POST",
    },
    [API_PATCH_USER]: {
      url: "/user/${id}",
      httpMethod: "PUT",
    },
    [API_PATCH_ACCOUNT]: {
      url: "/account/${id}",
      httpMethod: "PUT",
    },
    [API_RESET_USER_PASSWORD]: {
      url: "/user/${id}/reset_password",
      httpMethod: "POST",
    },

    // PROXY PACKAGES
    [API_FETCH_PROXY_PACKAGES]: {
      url: "/packages/proxy",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY_PACKAGES_FILE]: {
      url: "/packages/proxy/export",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY_PACKAGE]: {
      url: "/package/proxy/${id}",
      httpMethod: "GET",
    },
    [API_CREATE_PROXY_PACKAGE]: {
      url: "/package/proxy",
      httpMethod: "POST",
    },
    [API_UPDATE_PROXY_PACKAGE]: {
      url: "/package/proxy/${id}",
      httpMethod: "PUT",
    },
    [API_DELETE_PROXY_PACKAGE]: {
      url: "/package/proxy/${id}",
      httpMethod: "DELETE",
    },
    // PROXIES
    [API_FETCH_PROXIES]: {
      url: "/proxies",
      httpMethod: "GET",
    },
    [API_FETCH_PROXIES_FILE]: {
      url: "/proxies/export",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY]: {
      url: "/proxy/${id}",
      httpMethod: "GET",
    },
    [API_CREATE_PROXY]: {
      url: "/proxy",
      httpMethod: "POST",
    },
    [API_UPDATE_PROXY]: {
      url: "/proxy/${id}",
      httpMethod: "PUT",
    },
    [API_DELETE_PROXY]: {
      url: "/proxy/${id}",
      httpMethod: "DELETE",
    },
    [API_GET_PROXY_TEMPLATE]: {
      url: "/proxy/template",
      httpMethod: "GET",
    },
    [API_IMPORT_PROXY]: {
      url: "/proxy/import/${packageId}",
      httpMethod: "POST",
    },
    // Subscription
    [API_FETCH_SUBSCRIPTION_PACKAGES]: {
      url: "/packages",
      httpMethod: "GET",
    },
    [API_CREATE_SUBSCRIPTION_PACKAGE]: {
      url: "/package",
      httpMethod: "POST",
    },
    [API_UPDATE_SUBSCRIPTION_PACKAGE]: {
      url: "/package/${packageId}",
      httpMethod: "PUT",
    },
    [API_DELETE_SUBSCRIPTION_PACKAGE]: {
      url: "/package/${packageId}",
      httpMethod: "DELETE",
    },
    // DICTIONARIES
    [API_FETCH_ACCOUNT_DICTIONARY]: {
      url: "/dictionaries/accounts",
      httpMethod: "GET",
    },
    [API_FETCH_PACKAGE_DICTIONARY]: {
      url: "/dictionaries/packages",
      httpMethod: "GET",
    },
    [API_FETCH_ACTION_GROUP_DICTIONARY]: {
      url: "/dictionaries/action-group",
      httpMethod: "GET",
    },
    [API_FETCH_PARSING_GROUP_DICTIONARY]: {
      url: "/dictionaries/parsing-groups",
      httpMethod: "GET",
    },
    // DASHBOARD
    [API_GET_DASHBOARD]: {
      url: "/dashboard",
      httpMethod: "GET",
    },
    // Admins
    [API_FETCH_ADMINS]: {
      url: "/admins",
      httpMethod: "GET",
    },
    [API_CREATE_ADMIN]: {
      url: "/admin",
      httpMethod: "POST",
    },
    [API_DELETE_ADMIN]: {
      url: "/admin/${id}",
      httpMethod: "DELETE",
    },
    [API_UPDATE_ADMIN]: {
      url: "/admin/${id}",
      httpMethod: "PUT",
    },
    [API_SET_ADMIN_PERMISSIONS]: {
      url: "/admin/${id}/permissions",
      httpMethod: "POST",
    },
    [API_FETCH_ACL_RESOURCES]: {
      url: "/resources",
      httpMethod: "GET",
    },
    // Parsing groups
    [API_FETCH_PARSING_GROUPS]: {
      url: "/parsing_groups",
      httpMethod: "GET",
    },
    [API_CREATE_PARSING_GROUP]: {
      url: "/parsing_group",
      httpMethod: "POST",
    },
    [API_DELETE_PARSING_GROUP]: {
      url: "/parsing_group/${id}",
      httpMethod: "DELETE",
    },
    [API_UPDATE_PARSING_GROUP]: {
      url: "/parsing_group/${id}",
      httpMethod: "PUT",
    },
    [API_FETCH_PARSING_GROUP]: {
      url: "/parsing_group/${id}",
      httpMethod: "GET",
    },
    [API_FETCH_PROJECTS]: {
      url: "/projects",
      httpMethod: "GET",
    },
    [API_FETCH_PROJECT]: {
      url: "/project/${id}",
      httpMethod: "GET",
    },
    [API_UPDATE_PROJECT]: {
      url: "/project/${id}",
      httpMethod: "PUT",
    },
    [API_FETCH_PROJECT_LOGS]: {
      url: "/project/${id}/log",
      httpMethod: "GET",
    },
    [API_FETCH_PROJECT_MANUAL_ARTICLES]: {
      url: "/project/${id}/manual_articles",
      httpMethod: "GET",
    },
    [API_CREATE_ARTICLE]: {
      url: "/project/manual_articles",
      httpMethod: "POST",
    },
    [API_FETCH_PROJECT_TASKS]: {
      url: "/project/${id}/tasks",
      httpMethod: "GET",
    },
    [API_CREATE_PROJECT_TASK]: {
      url: "/project/${id}/task",
      httpMethod: "POST",
    },
  },
};
