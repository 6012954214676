import _ from "lodash";
import apiCall from "@/utils/api";

import {
  API_FETCH_ACCOUNTS,
  API_FETCH_ACCOUNT,
  API_UPDATE_ACCOUNT_SUBSCRIPTION,
  API_CREATE_ACCOUNT,
  API_FETCH_ACCOUNT_USERS,
  API_FETCH_ACCOUNT_TRANSACTIONS,
  API_CREATE_ACCOUNT_TRANSACTION,
  API_FETCH_ACCOUNT_PACKAGES,
  API_FETCH_ACCOUNT_PACKAGE,
  API_UPDATE_ACCOUNT_PACKAGE,
  API_DELETE_USER,
  API_CREATE_USER,
  API_PATCH_USER,
  API_FETCH_ACCOUNT_DICTIONARY,
  API_RESET_USER_PASSWORD,
  API_FETCH_PACKAGE_DICTIONARY, API_PATCH_ACCOUNT,
} from "@/config/api.config";

import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
  MUTATE_SELECTED_PAGE,
  SET_ACCOUNTS_DATA,
  SET_PACKAGE_DICTIONARY,
} from "./mutations";

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const UPDATE_ACCOUNT_SUBSCRIPTION = "UPDATE_ACCOUNT_SUBSCRIPTION";
export const FETCH_ACCOUNTS_DICTIONARY = "FETCH_ACCOUNTS_DICTIONARY";
export const FETCH_PACKAGE_DICTIONARY = "FETCH_PACKAGE_DICTIONARY";
export const FETCH_ACCOUNT_TRANSACTIONS = "FETCH_ACCOUNT_TRANSACTIONS";
export const CREATE_TRANSACTION = "CREATE_TRANSACTION";
export const FETCH_ACCOUNT_PACKAGES = "FETCH_ACCOUNT_PACKAGES";
export const FETCH_ACCOUNT_PACKAGE = "FETCH_ACCOUNT_PACKAGE";
export const UPDATE_ACCOUNT_PACKAGE = "UPDATE_ACCOUNT_PACKAGE";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const FETCH_ACCOUNT_USERS = "FETCH_ACCOUNT_USERS";
export const DELETE_USER = "DELETE_USER";
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
export const EDIT_USER = "EDIT_USER";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_ACCOUNTS]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACCOUNTS,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: _.merge({}, getters.formattedFilters),
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_ACCOUNTS_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_ACCOUNT](context, { accountId }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACCOUNT,
        parts: { id: accountId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_ACCOUNT_SUBSCRIPTION](context, { accountId }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_UPDATE_ACCOUNT_SUBSCRIPTION,
        parts: { id: accountId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_ACCOUNT_TRANSACTIONS](context, { accountId, limit, offset, sort }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACCOUNT_TRANSACTIONS,
        params: {
          limit: limit,
          offset: offset,
          sortBy: sort,
        },
        parts: { id: accountId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_ACCOUNT_PACKAGES](context, { accountId, limit, offset, sort }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACCOUNT_PACKAGES,
        params: {
          limit: limit,
          offset: offset,
          sortBy: sort,
        },
        parts: { id: accountId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_ACCOUNT_PACKAGE](context, { id }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACCOUNT_PACKAGE,
        parts: { id: id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_ACCOUNT_PACKAGE]: (context, { id, data }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_UPDATE_ACCOUNT_PACKAGE,
        data: data,
        parts: { id: id },
      })
          .then((response) => {
            let payload = response.data;
            //Provide the control to the next callback
            resolve(payload);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  [FETCH_ACCOUNTS_DICTIONARY]() {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACCOUNT_DICTIONARY,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_PACKAGE_DICTIONARY]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PACKAGE_DICTIONARY,
      }).then((response) => {
        try {
          let { status, data } = response;
          if (status === 200 && !data.error) {
            commit(SET_PACKAGE_DICTIONARY, data);
            resolve(data);
          } else {
            reject(data);
          }
        } catch (e) {
          reject();
        }
      });
    });
  },
  [FETCH_ACCOUNT_USERS](context, { accountId }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACCOUNT_USERS,
        params: {
          search: { accountId },
        },
      }).then((response) => {
        try {
          let { status, data } = response;
          if (status === 200 && !data.error) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (e) {
          reject();
        }
      });
    });
  },
  [DELETE_USER]: (context, { id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_DELETE_USER,
        parts: {
          id: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESET_USER_PASSWORD]: (context, { id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_RESET_USER_PASSWORD,
        parts: {
          id: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EDIT_USER]: (context, { user, id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: id ? API_PATCH_USER : API_CREATE_USER,
        data: user,
        parts: {
          id: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EDIT_ACCOUNT]: (context, { account, id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PATCH_ACCOUNT,
        data: account,
        parts: {
          id: id,
        },
      })
          .then((response) => {
            let payload = response.data;
            //Provide the control to the next callback
            resolve(payload);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  [CREATE_ACCOUNT]: (context, { account }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_CREATE_ACCOUNT,
        data: account,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_TRANSACTION]: (context, { accountId, transaction }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_CREATE_ACCOUNT_TRANSACTION,
        data: transaction,
        parts: { id: accountId },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
