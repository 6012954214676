import _ from "lodash";

//List of all possible filters for the sources
export const emptyFilters = {
  projectId: null,
  name: null,
  account: null,
  status: null,
  type: null,
};
export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  projects: [],
  projectItem: {
    id: null,
    name: null,
    accountId: null,
    accountName: null,
    accountEmail: null,
    type: null,
    articlesCount: null,
    sourcesCount: null,
    activeSourcesCount: null,
    sourceTypeList: null,
    status: null,
    lockStatus: null,
    systemSourcesEnabled: null,
    customSourcesEnabled: null,
    enableNotifications: null,
    enableReports: null,
    nextRun: null,
    lastCheckedAt: null,
    lastArticleAt: null,
    pausedAt: null,
    deletedAt: null,
    createdAt: null,
    updatedAt: null,
  },
  projectId: null,
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "createdAt",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
};
