import _ from "lodash";

// Source
export const defaultSourceFilters = [
  "id",
  "name",
  "enabled",
  "tag",
  "parsingStatus",
  "parsingGroup",
];
// Custom Source
export const defaultCustomSourceFilters = [
  "id",
  "name",
  "accountId",
  "accountName",
  "enabled",
  "parsingStatus",
];
// Channel
export const defaultChannelFilters = [
  "id",
  "name",
  "type",
  "parsingStatus",
  "lastParsed",
  "lastArticle",
];
// Source log
export const defaultSourceLogFilters = [
  "channelId",
  "channelName",
  "parsingType",
  "parsingStatus",
  "errorCode",
  "parsingDate",
  "proxyAddress",
];
// Accounts
export const defaultAccountFilters = ["id", "name", "email"];
// User logs
export const defaultUserLogsFilters = ["actionGroup", "userId", "userName"];
// Tag
export const defaultTagFilters = ["name"];

// Proxy package
export const defaultProxyPackageFilters = ["name"];

// Proxy
export const defaultProxyFilters = ["name", "enabled", "status"];

// Admin
export const defaultAdminFilters = ["isActive"];

// Project
export const defaultProjectFilters = ["projectId", "name", "account", "type", "status"];

export const defaultProjectLogsFilters = ["createdAt", "userId", "userName"];
export const defaultProjectManualArticlesFilters = ["url", "publishedAt"];
export const defaultProjectTasksFilters = ["status", "actionType"];

export default {
  // Account
  availableAccountFilters: {
    id: "Account Id",
    name: "Account / User name",
    email: "Account / User email",
    currentPackage: "Current package",
    nextPackage: "Next package",
  },
  // User log
  availableUserLogsFilters: {
    actionGroup: "Action group",
    createdAt: "Date",
    entityId: "Entity ID",
    entityName: "Entity name",
    userId: "User ID",
    userName: "User name",
  },
  // Admin
  availableAdminFilters: {
    allowedResources: "Allowed resources",
    name: "Name",
    email: "E-mail",
    isActive: "Enabled",
  },
  selectedAdminFilters: _.merge([], defaultAdminFilters),
  // Source
  availableSourceFilters: {
    id: "Source Id",
    name: "Source domain",
    enabled: "Enabled",
    channelId: "Channel Id",
    channelName: "Channel name",
    channelUrl: "Channel url",
    tag: "Source tag",
    parsingGroup: "Parsing group",
    parsingStatus: "Parsing status",
    lastRun: "Last run",
  },
  selectedSourceFilters: _.merge([], defaultSourceFilters),
  // Custom Source
  availableCustomSourceFilters: {
    id: "Source Id",
    name: "Source domain",
    accountId: "Account Id",
    accountName: "Account name",
    enabled: "Enabled",
    channelUrl: "Channel url",
    tag: "Source tag",
    parsingGroup: "Parsing group",
    parsingStatus: "Parsing status",
    lastRun: "Last run",
    projectId: "Project Id",
  },
  selectedCustomSourceFilters: _.merge([], defaultCustomSourceFilters),
  // Channel
  availableChannelFilters: {
    id: "Channel Id",
    name: "Channel name",
    enabled: "Enabled",
    type: "Channel type",
    url: "Channel URL",
    parsingStatus: "Parsing status",
    lastParsed: "Last parsed",
    lastArticle: "Last article",
  },
  selectedChannelFilters: _.merge([], defaultChannelFilters),
  // Source log
  availableSourceLogFilters: {
    channelId: "Channel Id",
    channelName: "Channel name",
    parsingType: "Parsing type",
    parsingStatus: "Parsing status",
    errorCode: "Error code",
    parsingDate: "Parsing date",
    proxyAddress: "Proxy address",
  },
  selectedSourceLogFilters: _.merge([], defaultSourceLogFilters),
  selectedAccountFilters: _.merge([], defaultAccountFilters),
  selectedUserLogsFilters: _.merge([], defaultUserLogsFilters),
  // Tag
  availableTagFilters: {
    // id: "Tag Id",
    name: "Tag name",
  },
  selectedTagFilters: _.merge([], defaultTagFilters),
  // Proxy package
  availableProxyPackageFilters: {
    // id: "Package Id",
    name: "Package name",
  },
  selectedProxyPackageFilters: _.merge([], defaultProxyPackageFilters),
  // Proxy
  availableProxyFilters: {
    name: "Address",
    status: "Status",
    enabled: "Enabled",
  },
  selectedProxyFilters: _.merge([], defaultProxyFilters),

  // Project
  availableProjectFilters: {
    projectId: "Project Id",
    name: "Name",
    account: "Account Name/Email",
    status: "Status",
    type: "Type",
  },
  selectedProjectFilters: _.merge([], defaultProjectFilters),

  // Project log
  availableProjectLogsFilters: {
    createdAt: "Date",
    userId: "User ID",
    userName: "User name",
  },
  selectedProjectLogsFilters: _.merge([], defaultProjectLogsFilters),

  // Project manual articles
  availableProjectManualArticlesFilters: {
    publishedAt: "Published",
    url: "URL",
  },
  selectedProjectManualArticlesFilters: _.merge([], defaultProjectManualArticlesFilters),

  // Project tasks
  availableProjectTasksFilters: {
    actionType: "Action type",
    status: "Status",
  },
  selectedProjectTasksFilters: _.merge([], defaultProjectTasksFilters),
};
